import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  Header,
  Banner,
  Plans,
  About,
  Contact,
  Policy,
  Footer,
} from "../../components/replica";
import { useParams, useHistory } from "react-router-dom";
import ReplicaSiteService from "../../../service/replica/replica";
import { getTableContainerUtilityClass } from "@mui/material";

function Privacy() {
  const path = useParams();
  const history = useHistory();
  const service = new ReplicaSiteService();
  const [state, setState] = useState({
    header_data: [],
    policy_data: [],
    banner_data: [],
  });

  useEffect(() => {
    service.getHeader(path.user_name).then((res) => {
      if (res.status) {
        console.log(res.data);
        setState((prevState) => ({
          ...prevState,
          header_data: res.data,
        }));
      } else {
        history.push("/login");
      }
    });
    getBanner();
    //getPolicy();
  }, []);

  const getBanner = () => {
    service.getBanner(path.user_name).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          banner_data: res.data,
        }));
      } else {
        history.push("/login");
      }
    });
  };
  const getPolicy = () => {
    service.getPolicy(path.user_name).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          policy_data: res.data,
        }));
      } else {
        alert("Invalid Url");
      }
    });
  };

  return (
    <div className="h-100">
      <>
        {state.header_data && (
          <Header
            data={state.header_data}
            user_name={path.user_name}
            showmenu={false}
          />
        )}

        {state?.header_data?.content?.policy && (
          <Policy data={state} policy_data={state.header_data.content.policy} />
        )}

        {state?.header_data?.content?.policy && (
          <Footer data={state} user_name={path.user_name} />
        )}
      </>
    </div>
  );
}
export default withTranslation()(withRouter(Privacy));
