import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class DownloadService {
  key = localStorage.getItem("apiKey");
  async getDocument() {
    const response = await fetch(BASE_URL + "tools/download_product", {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    return await response.json();
  }
  async getCategory() {
    const response = await fetch(BASE_URL + "tools/get_categories", {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    return await response.json();
  }
}
