import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import style from "./profile.module.scss";
import { FormInputControl } from "../common";
import LoginService from "../../../service/Auth/Login";
import ProfileService from "../../../service/profile";
import { useHistory } from "react-router-dom";
import { PasswordValidation } from "../../hook/passwordValidation";
import { usePopperTooltip } from "react-popper-tooltip";
import { animated, useTransition } from "react-spring";
import proPic from "../../../assets/images/nophoto/no_photo.jpg";
import { useSelector } from "react-redux";

import ReCAPTCHA from "react-google-recaptcha";
import Modal from "react-awesome-modal";

function ProilfeModal({ show, extraData, onHover, id }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <Modal
      visible={show}
      width="400"
      height="300"
      effect="fadeInDown"
      onClickAway={() => onHover()}
    >
      <div className={style.profileModalClass}>
        <div className={style.avatarImage}>
          <div className={style.avatarPreview}>
            <img src={proPic} alt="" />
            <h3
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_ProfileFullName}`
                  : `${style.ProfileFullName}`
              }`}
              style={{
                fontSize: "20px",
              }}
            >
              {`${extraData[id]?.details?.name}  ${extraData[id]?.details?.second_name}  `}
            </h3>
            <h5
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_ProfileUserName}`
                  : `${style.ProfileUserName}`
              }`}
            >
              {extraData[id]?.username}
            </h5>
            <p
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_UserEmail}`
                  : `${style.UserEmail}`
              }`}
              style={{ marginBottom: "8px", display: "flex" }}
            >
              <i
                class="fa fa-envelope"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              ></i>{" "}
              {extraData[id]?.email}
            </p>
            <p
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_UserEmail}`
                  : `${style.UserEmail}`
              }`}
              style={{ marginBottom: "5px", display: "flex" }}
            >
              <i
                class="fa fa-phone"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              ></i>
              {extraData[id]?.details?.mobile}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ProilfeModal;
