import React, { useEffect } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";

function Policy(props) {
  const { t } = useTranslation();
  console.log(props);
  return (
    <div className="container">
      <section className="plan_cnt_sec" style={{ marginTop: "90px" }}>
        <h1> {t("Replica.privacy")}</h1>
        <div className="container">
          <div
            dangerouslySetInnerHTML={{
              __html: props.policy_data && props.policy_data,
            }}
          ></div>
        </div>
      </section>
    </div>
  );
}

export default Policy;
