import React from "react";
import { Helmet } from "react-helmet";
import { PageTitle } from "../../components/common";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation } from "react-i18next";
import Content from "./Content";

function Rankadvancement() {
  const { t } = useTranslation();
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.rankadvancement")}
        </title>
      </Helmet>
      <PageTitle title="rank_advancement" />
      <Content />
    </div>
  );
}

export default Rankadvancement;
