import React, { useEffect, useState } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { IMG_URL } from "../../../configuration/apiconfig";
import { useHistory } from "react-router-dom";

function BlogBanner(props) {
  return (
    <>
      <section
        id="home"
        className="banner_section"
        style={{
          backgroundImage: `url(http://newbreak.church/wp-content/uploads/2021/08/qofjunxy9ly.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="banner_contant_sec">
            <h1
              style={{
                color: "white",
                fontWeight: "bolder",
                fontFamily: "fantasy",
                fontSize: "100px",
              }}
            >
              Read Our Blog
            </h1>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogBanner;
