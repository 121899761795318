import React, { useEffect, useState } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { IMG_URL } from "../../../configuration/apiconfig";

function BlogDetails({ blogdeatils }) {
  const htmlContent = `
  <p style="text-align: center;"><span class="display-5" style="color: rgb(0, 0, 0); font-size: 36pt; font-family: 'comic sans ms', sans-serif;">Climate action: How to put communities first</span></p>
  <pre class="title_meta" style="text-align: center;">A top-down approach to climate change programs often ignores the needs of the marginalized. Here's how locally led adaptation, based on principles of accountability and community, can help fix this.</pre>
  <div class="row author_info single_author_info">&nbsp;</div>
  <p style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="https://idronline.org/wp-content/uploads/2023/10/rural-women-making-a-solar-energy-machine-1.jpg" alt="" width="1017" height="678">People are already adapting and are resilient, not just to climate change but to all kinds of change. | Picture courtesy:&nbsp;<a href="https://www.flickr.com/photos/iloasiapacific/7621417492/" target="_blank" rel="noreferrer noopener">Flickr</a>&nbsp;/&nbsp;<a href="https://creativecommons.org/licenses/by-nc-nd/2.0/" target="_blank" rel="noreferrer noopener">CC BY</a></p>
`;
  const contentObject = { __html: blogdeatils[0]?.content };

  return (
    <>
      {console.log(blogdeatils[0]?.content)}
      <section
        id="plan"
        className="blog_cnt_sec mt-10"
        style={{ marginTop: "10%" }}
      >
        <div className="container">
          <div dangerouslySetInnerHTML={contentObject} />
        </div>
      </section>
    </>
  );
}

export default BlogDetails;
