import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Header, Scrollbutton, BlogDetails } from "../../components/replica";
import { useParams } from "react-router-dom";
import ReplicaSiteService from "../../../service/replica/replica";
import AppInfoservice from "../../../service/common/Appinfo";
import DashboardModel from "../../components/dashboard/DashboardModal";
import AddForm from "../../components/dashboard/AddForm";

function BlogContent() {
  const { user_name, admin, id } = useParams();
  // const params = useParams();
  const service = new ReplicaSiteService();
  const app = new AppInfoservice();

  const [state, setState] = useState({
    header_data: [],
    policy_data: [],
    banner_data: [],
    blog_details: [],
    loding: true,
    logo: "",
    show: true,
  });

  useEffect(() => {
    let admin_username = admin ? admin : "";
    service.getApi(admin_username).then((res) => {
      if (res.status) {
        localStorage.setItem("apiKey", res.data.key);
        service.getHeader(user_name, admin_username).then((res) => {
          if (res.status) {
            setState((prevState) => ({
              ...prevState,
              header_data: res.data,
              loding: false,
            }));
          } else {
            //alert("Invalid Url");
          }
          app.Appinfo().then((res) => {
            if (res.status) {
              setState((prevState) => ({
                ...prevState,
                logo: res.data.company_info.login_logo,
              }));
            }
          });
        });
        getBlogDetails();
      } else {
        alert("Invalid Admin user name");
      }
    });
  }, []);

  const getBlogDetails = () => {
    service.getBlogDetails(user_name, id).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          blog_details: res.data,
        }));
      } else {
      }
    });
  };
  const cancel = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  return (
    <div className="h-100">
      {!state.loding && (
        <>
          {state.header_data && (
            <Header
              data={state.header_data}
              user_name={user_name}
              logo={state.logo}
              showmenu={true}
            />
          )}
          <BlogDetails blogdeatils={state.blog_details} />

          {/* {state.policy_data && <Plans data={state} />}
          {state.policy_data && <About data={state} />}
          {state.policy_data && <Contact data={state} user_name={user_name} />} */}
          <Scrollbutton />
        </>
      )}
    </div>
  );
}

export default withTranslation()(withRouter(BlogContent));
