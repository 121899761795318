import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "./objective.module.scss";
import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgressBar from "../../components/Circularprogrssbar/Circularprogress";
import RankadvService from "../../../service/rank/rank";
import Target from "../../../assets/images/target.png";
import TargetWhite from "../../../assets/images/target_wht.png";
import { useTranslation } from "react-i18next";
function Content() {
  const { currentTheme } = useSelector((state) => state.Theme);
  const themes = useTheme();

  const [Rankname, setRankname] = useState();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [Rankdetails, setRankdetails] = useState();
  const service = new RankadvService();
  const { t } = useTranslation();
  const StyledTabs = styled((props) => (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      // backgroundColor: '#635ee7',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),

      color: currentTheme == "theme-dark" ? "#868686" : "#00090",
      background: currentTheme == "theme-dark" ? "" : "#E5E5E550",
      "&.Mui-selected": {
        color: currentTheme == "theme-dark" ? "#fff" : "rgb(88, 147, 241)",
        backgroundColor: currentTheme != "theme-dark" ? "#ffffff" : "",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );
  const [isTabChanging, setIsTabChanging] = useState(false);

  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 400);
    setSelectedTab(newValue);
    rankDetails(newValue + 1);
  };
  const rankDetails = (id) => {
    service.rankbasedCommisson(id).then((res) => {
      console.log(res);
      setRankdetails(res.data.ranks);
    });
  };

  //progress bar styling mui
  const BorderLinearProgress = styled(LinearProgress)(({ theme, success }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: success == true ? "#3dd89a" : "red",
      //  theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  useEffect(() => {
    service.ranklist().then((res) => {
      setRankname(res.data.ranks);
      const executiveInfluencerId =
        res.data.ranks.find((item) => item.current_rank === true)?.id || 1;

      setSelectedTab(executiveInfluencerId - 1);
      rankDetails(executiveInfluencerId);
    });
  }, []);

  return (
    <>
      <div className={style.MainContainer}>
        <div className={style.summary}>
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tabContent}`
                : `${style.tabContent}`
            }`}
          >
            <StyledTabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="styled tabs example"
              style={
                isTabChanging
                  ? { opacity: 0, transition: "opacity 0.3s ease" }
                  : {}
              }
            >
              {Rankname &&
                Rankname.map((item) => <StyledTab label={item?.name} />)}
            </StyledTabs>
          </div>
        </div>
        <>
          {Rankdetails && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  style={
                    isTabChanging
                      ? {
                          opacity: "70%",
                          // filter: "blur(1px)",
                          transition: "filter 0.5s ease",
                        }
                      : {}
                  }
                  className={`${
                    currentTheme == "theme-dark"
                      ? `${style.subcontainercarddark}`
                      : `${style.subcontainercard}`
                  }`}
                >
                  <h3>
                    Requirements
                    {currentTheme == "theme-dark" ? (
                      <img
                        src={TargetWhite}
                        style={{ width: 25, height: 25, margin: 10 }}
                      ></img>
                    ) : (
                      <img
                        src={Target}
                        style={{ width: 25, height: 25, margin: 10 }}
                      />
                    )}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {Rankdetails &&
                      Rankdetails.rank_deatils.map((pvdetail) => (
                        <>
                          <div style={{ flex: "1", padding: "10px" }}>
                            <h5>{t("Common." + pvdetail.text)}</h5>
                            <BorderLinearProgress
                              variant="determinate"
                              value={pvdetail.percentage}
                              className={style.progress}
                              success={true}
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                margin: "10%",
                                height: "auto",
                              }}
                            ></div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </>
      </div>
    </>
  );
}

export default Content;
