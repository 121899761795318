import React from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import PageTitle from "../../components/common/pageTitle";
import { DownloadContent } from "../../components/tools";
import { Button, Row, Col } from "react-bootstrap";

function DownloadMaterial(props) {
  const { t } = props;
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.downloadMaterial")}
        </title>
      </Helmet>
      <PageTitle title="downloadMaterial" buttonOn={false} />

      <DownloadContent />
    </div>
  );
}
export default withTranslation()(withRouter(DownloadMaterial));
