import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";

const BASE_URL = "https://admin.luxyaglobal.com/node_api/web/";
const IMG_URL = "https://admin.luxyaglobal.com/node_api/uploads/images/";
const LEAD_URL = "https://admin.luxyaglobal.com/node_api/web/";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// const DEFAULT_KEY ='903'
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const BASE = "https://admin.luxyaglobal.com/";

// const BASE_URL = "http://162.19.146.132:1288/node_api/web/";
// const IMG_URL = "http://162.19.146.132:1288/node_api/uploads/images/";
// const LEAD_URL = "http://162.19.146.132:1288/node_api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://162.19.146.132:1288/";

// const BASE_URL = "http://192.168.7.37:5002/node_api/web/";
// const IMG_URL = "http://node.demo5.iossmlm.com:5000/uploads/images/";
// const LEAD_URL = "http://node.demo5.iossmlm.com:5000/node_api/web/";
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const BASE = "http://node.demo5.iossmlm.com:5000/";
export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
};

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// //const BASE_URL = "http://127.0.0.1:5000/api/";
// const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://mlmadmin.demo5.iossmlm.com";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='14869ac2-d19b-497b-b0bb-4845393e11f9'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.7.74/garage/work-copy/mlm/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://192.168.7.74/garage/work-copy/mlm/uploads/images/";
// const BASE = "http://192.168.7.74/garage/work-copy/mlm/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = 'bfd6eb01-9991-4380-88bc-c42852a8dbcc'
// //const DEFAULT_KEY = '14963'
// console.log(localStorage);
// export { COMPANY_NAME, BASE_URL, IMG_URL, API_KEY, BASE, DEFAULT_KEY };
