import React, { useEffect, useState } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import { EarningsTable } from "./";
import Tabs from "react-responsive-tabs";
import style from "./thirteenth.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};

function Content({
  tableData,
  changeHandler,
  paginationHandler,
  repurchase_status,
  exportData,
  loader,
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    "statement",
    "transferHistory",
    "purchaseWallet",
    "earning",
  ]);
  useEffect(() => {
    if (repurchase_status !== "yes") {
      setTabs(["statement", "transferHistory", "earning"]);
    }
  }, [repurchase_status]);
  // const tabs = ['statement','transferHistory','purchaseWallet','earning']

  // const getTabs = () => {
  //   return tabs.map((tab, index) => ({
  //     title: t("Common." + tab),
  //     getContent: () => getContent(tab),
  //     /* Optional parameters */
  //     key: tab,
  //     tabClassName: style.TabClass,
  //     panelClassName: style.TabPanel,
  //     // tabsWrapperClass : 'safdsadfads'
  //   }));
  // };
  //get content
  // const getContent = (contentId) => {
  //   if (contentId === "statement") {
  //     return (
  //       <div className={style.dataTable} style={stylees}>
  //         <StatementTable
  //           ContentData={tableData.statement}
  //           paginationchange={paginationHandler.statement}
  //         />
  //       </div>
  //     );
  //   } else if (contentId === "transferHistory") {
  //     return (
  //       <div className={style.dataTable} style={stylees}>
  //         <HistoryTable
  //           loader={loader}
  //           ContentData={tableData.history}
  //           paginationchange={paginationHandler.history}
  //         />
  //       </div>
  //     );
  //   } else if (contentId === "purchaseWallet") {
  //     return (
  //       <div className={style.dataTable} style={stylees}>
  //         <WalletTable
  //           ContentData={tableData.wallet}
  //           paginationchange={paginationHandler.wallet}
  //         />
  //       </div>
  //     );
  //   } else if (contentId === "earning") {
  //     return (
  //       <div className={style.dataTable} style={stylees}>
  //         <EarningsTable
  //           loader={loader}
  //           ContentData={tableData.earnings}
  //           paginationchange={paginationHandler.earnings}
  //           exportData={exportData}
  //         />
  //       </div>
  //     );
  //   }
  // };
  return (
    <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <div className={style.dataTable} style={stylees}>
          <EarningsTable
            loader={loader}
            ContentData={tableData.earnings}
            paginationchange={paginationHandler.earnings}
            exportData={exportData}
          />
        </div>

        {/* <Tabs
          items={getTabs()}
          transform={true}
          transformWidth={734}
          onChange={changeHandler}
          selectedTabKey={tableData.key}
        /> */}
      </div>
    </div>
  );
}

export default Content;
