import React from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./network.module.scss";
import { FormInputControl, CustomButton } from "../common";
const SearchContent = (props) => {
  const { t } = useTranslation();
  return (
    <Card className={style.search}>
      <Row>
        <Col md={4} sm={12}>
          <div className={style.Buttons}>
            <Button
              className={style.downlineButton}
              onClick={props.buttonClick}
            >
              <i className="fa fa-forward"></i>
              {t(`Sidemenu.${props.buttonLabel}`)}
            </Button>
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div>
            <Form className={style.searchForm} onSubmit={props.searchClick}>
              <FormInputControl
                required={false}
                error={props.error}
                formClass={`mb-0`}
                inputProps={{
                  type: "text",
                  name: "username",
                  value: props.username,
                  disabled: false,
                  onChange: props.changeHandler,
                }}
              />
              <div>
                <CustomButton
                  variant="primary"
                  type="submit"
                  btnclass={style.m0}
                  disabled={props.searchloader}
                >
                  {}
                  {props.searchloader ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  ) : (
                    ""
                  )}

                  {t("Button.search")}
                </CustomButton>

                <CustomButton
                  variant="info"
                  onClick={props.reset}
                  type="button"
                  btnclass={style.m0}
                  disabled={props.resetloader}
                >
                  {props.resetloader ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  ) : (
                    ""
                  )}
                  {t("Button.reset")}
                </CustomButton>
              </div>
            </Form>
          </div>
        </Col>
        <Col md={2} sm={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#FFC72C",
                borderRadius: "50%",
                margin: "10px",
              }}
            ></div>
            <span style={{ marginLeft: "5px" }}>Collabrator</span>
            <div
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: "#72A0C1",
                borderRadius: "50%",
                margin: "10px",
              }}
            ></div>
            <span style={{ marginLeft: "5px" }}>Customer</span>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default SearchContent;
