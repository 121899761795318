import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import style from "./tools.module.scss";
import AddonNotification from "../common/AddonNotification";
import { useTranslation } from "react-i18next";
// import {useTranslation} from 'react-i18next';
// import moment from 'moment';
import AlertBs from "../common/AlertBs";
import { useSelector } from "react-redux";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import { Editor } from "@tinymce/tinymce-react";

ClassicEditor.create(document.querySelector("#editor"), {
  // plugins: [Essentials, Paragraph, Bold, Italic],
  toolbar: ["bold", "italic"],
})
  .then((editor) => {
    console.log("Editor was initialized", editor);
  })
  .catch((error) => {
    console.error(error.stack);
  });

const BlogContent = (props) => {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);

  const [notes, setNotes] = useState(null);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  // useEffect(() => {
  //   let html = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(html);
  // }, [editorState]);

  // console.log(convertedContent);
  // function createMarkup(html) {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // }

  let imagePreview = "";
  if (props.changedImage.show) {
    imagePreview = (
      <div className={style.previewZone}>
        <div className={style.box}>
          <div className={style.boxHeader}>
            <div>{t("Button.preview")}</div>
            <div className={style.boxTools}>
              <button
                type="button"
                onClick={props.resetBanner}
                className={style.removePreview}
              >
                <i className={style.faTimes}></i>
                {t("Button.reset")}
              </button>
            </div>
          </div>
          <div className={style.boxBody}>
            <img width="200" src={props.changedImage.file} alt="" />
            <p>{props.changedImage.name}</p>
          </div>
        </div>
      </div>
    );
  }

  let uploadBtn = "";
  if (props.changedImage.uploadFile) {
    uploadBtn = (
      <Button
        variant="primary"
        type="button"
        onClick={props.uploadBanner}
        className={`"mtp30 ml-1" ${style.uploadButton}`}
      >
        {props.uploader ? (
          <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
        ) : (
          ""
        )}
        Upload
      </Button>
    );
  }
  function onChange(evt) {
    var newContent = evt.editor.getData();
    setNotes(newContent);
  }

  function onBlur(evt) {}

  function afterPaste(evt) {
    var newContent = evt.editor.getData();
    setNotes(newContent);
  }

  const handleEditorChange = (event) => {
    console.log(event);
  };

  return (
    <div className={style.downloadConatiner}>
      {/* <Row>
        <Col>
          <AlertBs {...props.alert} close={props.closeAlert} />
          <Card>
            <Card.Body
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_replicaContent}`
                  : `${style.replicaContent}`
              }`}
            >
              <div>
                <h4
                  className={`${
                    currentTheme == "theme-dark" && `${style.dark_mode_heading}`
                  }`}
                >
                  {t("Common.currenttopbanner")}
                </h4>
                <div className={style.uploadContainer}>
                  <img src={props.replica_banner} alt="" />
                </div>

                {imagePreview}

                <Form.Label>{t("Common.uploadtopbanner")}</Form.Label>

                <div className={style.dropzoneWrapper}>
                  <div className={style.dropzoneDesc}>
                    <i className="fa fa-download" aria-hidden="true"></i>
                    <p>{t("Common.chooseimagefiledrag")}</p>
                    <div className={style.dropzoneDesc2}>
                      <span
                        className={`${
                          currentTheme == "theme-dark" &&
                          `${style.dark_mode_heading}`
                        }`}
                      >
                        {t("Common.chooseimgfile")}
                      </span>
                      <br />
                      <span
                        className={`${
                          currentTheme == "theme-dark" &&
                          `${style.dark_mode_heading}`
                        }`}
                      >
                        {t("Common.maxsize")}
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="banner_image"
                    id="banner_image"
                    className={style.dropzone}
                    onChange={props.changeBanner}
                  />
                </div>
              </div>
              {uploadBtn}
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Form onSubmit={props.onSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Blog Title </Form.Label>
                  <Form.Control
                    type="text"
                    value={props.blog_title}
                    placeholder="Enter Title"
                    onChange={(event) =>
                      props.onSubmitBlogContent(
                        event.target.value,
                        "blog_title"
                      )
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Blog Sub Title </Form.Label>
                  <Form.Control
                    type="text"
                    value={props.blog_subtitle}
                    placeholder="Enter Sub Title"
                    onChange={(event) =>
                      props.onSubmitBlogContent(
                        event.target.value,
                        "blog_subtitle"
                      )
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Upload Cover photo</Form.Label>
                  <Form.Control type="file" onChange={props.changeBanner} />
                  <div
                    className={style.dropzoneDesc2}
                    style={{
                      color: "firebrick",
                      fontSize: "12px",
                    }}
                  >
                    <span
                      className={`${
                        currentTheme == "theme-dark" &&
                        `${style.dark_mode_heading}`
                      }`}
                    >
                      {t("Common.chooseimgfile")}
                    </span>
                    <br />
                    <span
                      className={`${
                        currentTheme == "theme-dark" &&
                        `${style.dark_mode_heading}`
                      }`}
                    >
                      {t("Common.maxsize")}
                    </span>
                  </div>
                </Form.Group>

                <Row>
                  <Col>
                    <Card>
                      <Card.Body>
                        <h4
                          className={`${
                            currentTheme == "theme-dark" &&
                            `${style.dark_mode_heading}`
                          }`}
                        >
                          Blog Content
                        </h4>
                        <Editor
                          apiKey="kuejqukb2ldp1yqijxhkw6t3cki8deyziupgy42rc2kxnsac"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          //initialValue={props?.replica_content?.plan}
                          onEditorChange={(newValue, editor) => {
                            props.onSubmitBlogContent(newValue, "blog_content");
                            // setText(editor.getContent({format: 'text'}));
                          }}
                          init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                              "importcss",
                            ],
                            toolbar:
                              "undo redo | blocks | code  " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px },",
                            content_css: [
                              "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css",
                              "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js",
                            ],
                          }}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Button type="submit">Submit</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BlogContent;
