import React, { useCallback, useEffect, useState } from "react";
import style from "../crm/crm.module.scss";
import { Button, Form } from "react-bootstrap";
import {
  DatePickers,
  FormInputControl,
  DropDownBs,
  CustomButton,
  AlertBs,
} from "../common";
//import moment from 'moment';
//service
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CrmService from "../../../service/crm/Crm";
import AppinfoService from "../../../service/common/Appinfo";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import collborator from "../../../assets/images/collaboration.png";

const InitialForm = {
  first_name: "",
  email_id: "",
  mobile_no: "",
  country: "",
  country_name: "",

  formError: {
    first_name: "",
    email_id: "",
    mobile_no: "",
    country: "",
    id: "",
  },
};
function AddForm(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();
  //const [status, setStatus] = useState(leadStatus);
  const [state, setState] = useState({
    ...InitialForm,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    editLoader: false,
  });

  //close alert
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };
  const onUrlclick = (url) => {
    Cookies.set("button", "false", { expires: 7 });
    window.location.href = url;
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
          : `${style.panel} ${style.papanel_defaultnel}`
      }`}
    >
      <div className={`${style.panel_body}`}>
        <AlertBs {...state.alert} close={closeAlert} />
        <div className="row  text-align-center justify-content-center">
          <img
            src={collborator}
            style={{ width: "100%", maxHeight: "50%", maxWidth: "50%" }}
          />
          <span
            style={{
              fontSize: "20px",
              //lineHeight: "-1px",
              color: "CaptionText",
              textAlign: "center",
            }}
          >
            {props.title}
          </span>
        </div>

        <div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              id="modal-btn-si"
              onClick={() => onUrlclick(props.signup)}
            >
              Sign up as Collaborator/Customer
            </button>
            <button
              type="button"
              class="btn btn-primary"
              id="modal-btn-si"
              onClick={() => onUrlclick(props.buynow)}
            >
              Buy Now
            </button>

            <button
              type="button"
              class="btn btn-primary"
              id="modal-btn-no"
              onClick={props.cancel}
            >
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddForm;
