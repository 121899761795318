import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class ReplicaService {
  key = localStorage.getItem("apiKey");

  // Get Leads
  async getReplicaBanner() {
    const response = await fetch(`${BASE_URL}tools/replica_banner`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    return await response.json();
  }
  async getReplicaConent() {
    const response = await fetch(`${BASE_URL}tools/replica_content`, {
      //url
      method: "GET", //method
      headers: {
        //passing header
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": this.key,
        "access-token": UserStore.key,
      },
    });
    return await response.json();
  }

  async postReplica(data) {
    const response = await fetch(`${BASE_URL}tools/replica_add`, {
      //url
      method: "POST", //method
      headers: {
        "api-key": this.key,
        "access-token": UserStore.key,
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 204) {
      return {
        status: true,
      };
    } else {
      const res = await response.json();
      return res;
    }
  }
  async postBlog(data) {
    const response = await fetch(`${BASE_URL}tools/blog_add`, {
      //url
      method: "POST", //method
      headers: {
        "api-key": this.key,
        "access-token": UserStore.key,
        //"content-type": "application/json",
      },
      body: data,
    });
    if (response.status === 204) {
      return {
        status: true,
      };
    } else {
      const res = await response.json();
      return res;
    }
  }

  async updateBanner(data) {
    const response = await fetch(`${BASE_URL}tools/replica_banner`, {
      //url
      method: "POST", //method
      headers: {
        "api-key": this.key,
        "access-token": UserStore.key,
        // 'content-type'  : "application/json"
      },
      body: data,
    });
    if (response.status === 204) {
      return {
        status: true,
      };
    } else {
      const res = await response.json();
      return res;
    }
  }
}
