import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import { ReplicaContent } from "../../components/tools/";
import ReplicaService from "../../../service/tools/replica";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import Spinner from "../../shared/Spinner";

//data
import replicaData from "./replicadata.json";

function ReplicaConfiguration(props) {
  let service = new ReplicaService();

  const [state, setState] = useState({
    changedImage: {
      uploadFile: null,
      file: null,
      name: "",
      show: false,
    },
    replica_banner: "",
    loader: false,
    uploader: false,
    replica_content: [],

    alert: {
      show: false,
      message: "",
      varient: "",
    },
  });

  useEffect(() => {
    getReplicaBanner();
    getReplicaConent();
  }, []);

  const getReplicaBanner = () => {
    setState((prevstate) => ({
      ...prevstate,
      replica_banner: replicaData.replica_banner,
      loader: false,
    }));
    service.getReplicaBanner(state.keyword).then((res) => {
      if (res.status) {
        setState((prevstate) => ({
          ...prevstate,
          replica_banner: res.data.replica_banner,
          loader: false,
        }));
      } else {
        setState((prevstate) => ({
          ...prevstate,
          loader: false,
        }));
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  const getReplicaConent = () => {
    service.getReplicaConent(state.keyword).then((res) => {
      if (res.status) {
        console.log(res);
        setState((prevstate) => ({
          ...prevstate,
          replica_content: res.data,
          loader: false,
        }));
      } else {
        setState((prevstate) => ({
          ...prevstate,
          loader: false,
        }));
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  const changeBanner = (e) => {
    setState((prevstate) => ({
      ...prevstate,
      changedImage: {
        uploadFile: null,
        file: null,
        name: "",
        show: false,
      },
    }));
    let reader = new FileReader();
    reader.onload = (event) => {
      setState((prevstate) => ({
        ...prevstate,
        changedImage: {
          uploadFile: e.target.files[0],
          file: event.target.result,
          name: e.target.files[0].name,
          show: true,
        },
      }));
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const resetBanner = () => {
    setState((prevstate) => ({
      ...prevstate,
      changedImage: {
        uploadFile: null,
        file: null,
        name: "",
        show: false,
      },
    }));
  };
  const uploadBanner = (e) => {
    let formData = new FormData();
    formData.append("image", state.changedImage.uploadFile);
    setState((prevstate) => ({
      ...prevstate,
      uploader: true,
    }));
    service.updateBanner(formData).then((res) => {
      if (res.status) {
        // this.getLeads();
        setState((prevstate) => ({
          ...prevstate,
          alert: {
            show: true,
            message: t("Common.top_banner_updated"),
            varient: "success",
          },
          changedImage: {
            uploadFile: null,
            file: null,
            name: "",
            show: false,
          },
          uploader: false,
        }));
        getReplicaBanner();
      } else {
        let error_message = "";
        if (res.error.code === 1038) {
          error_message = "validation.tooManyUploadLimit";
        } else if (res.error.code === 1017) {
          error_message = "validation.fileTypeNotSupported";
        } else if (res.error.code === 1018) {
          error_message = "validation.fileTypeExceeded";
        } else if (res.error.code === 1024) {
          error_message = "Common.error_file_upload";
        } else if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1032) {
          error_message = "validation.chooseFile";
        }
        setState((prevstate) => ({
          ...prevstate,
          alert: {
            show: true,
            message: error_message,
            varient: "danger",
          },
          uploader: false,
        }));
      }
    });
  };
  const onSubmitReplicContent = (data, key) => {
    setState({
      ...state,
      replica_content: {
        ...state.replica_content,
        [key]: data,
      },
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    service.postReplica(state.replica_content).then((res) => {
      if (res.status) {
        setState((prevstate) => ({
          ...prevstate,
          alert: {
            show: true,
            message: "Upload Successful",
            varient: "success",
          },
          //  uploader: false,
        }));
      } else {
        if (res.error.code === 1002) {
          props.history.push("/logout");
        } else if (res.error.code === 1057) {
          props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  const { t } = props;
  return (
    <div className="h-100">
      {console.log(state)}
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.replica_configuration")}
        </title>
      </Helmet>
      <PageTitle title="replica_configuration" buttonOn={false} />
      {state.loader ? (
        <Spinner />
      ) : (
        <ReplicaContent
          {...state}
          alert={state.alert}
          changedImage={state.changedImage}
          changeBanner={changeBanner}
          resetBanner={resetBanner}
          uploadBanner={uploadBanner}
          replica_banner={state.replica_banner}
          replica_content={state.replica_content}
          onSubmitReplicContent={onSubmitReplicContent}
          onSubmit={onSubmit}
        ></ReplicaContent>
      )}
    </div>
  );
}
export default withTranslation()(withRouter(ReplicaConfiguration));
