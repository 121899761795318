import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import style from "./points.module.scss";
import { CurrencyDecimalFormat } from "../../helper";
function Tiles(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const Currency = useSelector((state) => state.curr);

  return (
    <div className={`${style.ewalletTiles}`}>
      {props.tilesData &&
        props.tilesData.map((tiles, index) => {
          return (
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_Tiles} d-flex`
                  : `${style.Tiles} d-flex`
              }`}
              key={index}
            >
              <div
                className={`${style.leftIcon} `}
                style={{
                  background: tiles.bg_color,
                }}
              >
                <img
                  src={
                    require("../../../assets/images/logos/income-w.png").default
                  }
                  alt=""
                />
              </div>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_tilesData}`
                    : `${style.tilesData}`
                }`}
              >
                <h4>{tiles.text}</h4>
                <span className={style.TilesAmount} title={tiles.amount}>
                  {tiles.amount}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default Tiles;
