import React, { useEffect } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar, Card, Button } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

function BlogList(props) {
  const htmlContent = `
  <p style="text-align: center;"><span class="display-5" style="color: rgb(0, 0, 0); font-size: 36pt; font-family: 'comic sans ms', sans-serif;">Climate action: How to put communities first</span></p>
  <pre class="title_meta" style="text-align: center;">A top-down approach to climate change programs often ignores the needs of the marginalized. Here's how locally led adaptation, based on principles of accountability and community, can help fix this.</pre>
  <div class="row author_info single_author_info">&nbsp;</div>
  <p style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="https://idronline.org/wp-content/uploads/2023/10/rural-women-making-a-solar-energy-machine-1.jpg" alt="" width="1017" height="678">People are already adapting and are resilient, not just to climate change but to all kinds of change. | Picture courtesy:&nbsp;<a href="https://www.flickr.com/photos/iloasiapacific/7621417492/" target="_blank" rel="noreferrer noopener">Flickr</a>&nbsp;/&nbsp;<a href="https://creativecommons.org/licenses/by-nc-nd/2.0/" target="_blank" rel="noreferrer noopener">CC BY</a></p>
`;
  const contentObject = { __html: htmlContent };
  const history = useHistory();

  const viewBlog = (id) => {
    history.push(`/blogdetails/${props.user_name}/${id}`);
  };

  return (
    <section id="plan" className="plan_cnt_sec">
      <div className="container">
        <h6
          style={{
            fontWeight: "bold",
          }}
        >
          WEEKLY ARTICLES WITH INSIGHT INTO THE WEEKEND'S MESSAGE
        </h6>
        <div className="row">
          {props.blogList.map((obj, index) => {
            return (
              <Card
                style={{ width: "16rem", marginTop: "5px", margin: "8px" }}
                key={index}
                onClick={() => viewBlog(obj.id)}
              >
                <Card.Img variant="top" src={obj.blog_img} />
                <Card.Body>
                  <Card.Title>{obj.title}</Card.Title>
                  <Card.Text>
                    {moment(obj.date_of_creation).format("MMM DD ,YYYY")}
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default BlogList;
