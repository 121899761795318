import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./points.module.scss";
import { Filter } from "./";
import moment from "moment";

function DownlinePoints({ ContentData, paginationchange, exportData, loader,types }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);



  const columns = [
      {
      name: "USERNAME",
      selector: (row) => row.membername ,
   
    },
    {
      name: "FROM USERNAME",
      selector: (row) => row.from_name ,
    
    },
     {
      name: "FROM NAME",
      selector: (row) => row.from_firstname ,
    
    },
    
    {
      name: t("Common.personalPv").toUpperCase(),
      selector: (row) => (<span style={{color:row.personal_pv >= 0 ? 'green' :'red'}}>{row.personal_pv ?? 0}</span>),
    
    },
    {
      name: t("Common.gpv").toUpperCase(),
      selector: (row) => (<span style={{color:row.group_pv >= 0 ? 'green' :'red'}}>{row.group_pv ?? 0}</span>),

      
    },
     {
      name: "SOURCE OF PV",
      selector: (row) => (row.pv_source).toUpperCase(),

      
    },
    //  {
    //   name: t("Common.totalPoints").toUpperCase(),
    //   selector: (row) => row.totalPoints ?? 0,

      
    // },
 
   
  ];
  const [state, setState] = useState({ data: [] });
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
  });

  const { data } = state;

  const handlePerRowsChange = (perPage, inPage) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");
    paginationchange(inPage, perPage, date.start, date.end, "", "", keys);
  };
  const handlePageChange = (inPage) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");

    paginationchange(
      inPage,
      ContentData.perPage,
      date.start,
      date.end,
      "",
      "",
      keys
    );
  };
  const handleOnSort = ({ selector }, sortDirection) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      date?.start,
      date?.end,

      sortDirection,
      selector,
      keys
    );
  };
  const filterHandlet = (start, end, type = "") => {
    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,
    }));
    let keys = [];
    type.map((key, idx) => {
      keys.push(key.key);
    });


    loader.EarningsTable.search = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      start,
      end,
      "",
      "",
      keys
    );
  };

  //reset handler
  const resetData = () => {
    SetDate((prev) => ({
      ...prev,
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    }));

    loader.EarningsTable.reset = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      moment().startOf("month").format("MMMM D, YYYY"),
      moment().format("MMMM D, YYYY"),
      "",
      "",
      ""
    );
  };
  const ConvertData = (data) => {
    let newData = data.map((obj) => {
      if (obj.key) {
        return {
          ...obj,
          key: t("ewallet." + obj.key),
        };
      } else {
        return {
          ...obj,
        };
      }
    });
    return newData;
  };
  return (
    <>
      <Filter
        filterHandler={filterHandlet}
        type={types}
        resetHandler={resetData}
        isSingle={true}
        exports={true}
        exportData={exportData}
        ContentData={ContentData}
        loader={loader}
      />



      <DataTable
        columns={columns}
        data={ContentData.data}
        highlightOnHover
        progressPending={ContentData.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={ContentData.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}

      />

    </>
  );
}

export default DownlinePoints;
