import React, { useState } from "react";
import style from "./dashboard.module.scss";
import { Popover, OverlayTrigger, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

function Links({ iscopy, icon, link }) {
  const { t } = useTranslation();
  function shareButton(url) {
    window.open(url, "_blank", "height=450,width=550");
  }
  const [state, setState] = useState({
    show: false,
  });
  const popover = (
    <Popover id="popover-basic" varient="success">
      <Popover.Title as="h3">{t("Dashboard.linkCopied")}</Popover.Title>
    </Popover>
  );
  function Clipboard_CopyTo(value) {
    var tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
  const openModal = () => {
    console.log(state.show);
    setState({
      ...state,
      show: !state.show,
    });
  };

  return (
    <>
      {iscopy ? (
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={popover}
          rootClose
        >
          <a onClick={() => Clipboard_CopyTo(link)}>
            <div className={style.profilesoc_share_bx_ico}>
              <i className={icon}></i>
            </div>
          </a>
          {/* <Button onClick={()=>Clipboard_CopyTo(link)} className={style.profilesoc_share_bx_ico}><i className={icon}></i></Button> */}
        </OverlayTrigger>
      ) : icon == "fa fa-qrcode" ? (
        <a
          onClick={() => openModal()}
          className={style.profilesoc_share_bx_ico}
        >
          <i className={icon}></i>
        </a>
      ) : (
        <a
          onClick={() => shareButton(link)}
          className={style.profilesoc_share_bx_ico}
        >
          <i className={icon}></i>
        </a>
      )}
      <div className="modal">
        <div className="modal-content">
          <Modal show={state.show}>
            <Modal.Header
              style={{ background: "white" }}
              closeButton
              onClick={openModal}
            >
              <Modal.Title style={{ background: "white" }}>
                Show Case QR Code
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                background: "white",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {icon == "fa fa-qrcode" && (
                <QRCode
                  title="Show Case"
                  value={link}
                  bgColor={"#FFFFFF"}
                  fgColor={"#000000"}
                  size={256}
                />
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Links;
