import { Button, Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import style from "./tools.module.scss";
import DownloadService from "../../../service/tools/download";
import { useHistory } from "react-router-dom";
import { BASE } from "../../../configuration/apiconfig";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
import { IMG_URL } from "../../../configuration/apiconfig";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";

const DownloadContent = () => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const history = useHistory();
  const [state, setState] = useState([]);
  const [category, setCategory] = useState([]);
  const [catId, setCatId] = useState();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const service = new DownloadService();
    setLoading(true);
    service.getDocument().then((res) => {
      if (res.status) {
        setState(res.data.documen_data);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.error.code === 1002) {
          history.push("/logout");
        } else if (res.error.code === 1057) {
          history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
    getCategory();
  }, [history]);

  const getCategory = () => {
    const service = new DownloadService();
    service.getCategory().then((res) => {
      if (res.status) {
        setCategory(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.error.code === 1002) {
          history.push("/logout");
        } else if (res.error.code === 1057) {
          history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  const download = (e) => {
    var fn = e.match(/([^/])+/g);
    fn = fn[fn.length - 1];
    fetch(e).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fn;
        a.click();
      });
    });
  };

  const docIcon = (id) => {
    if (id === "1") {
      return (
        <img
          src={BASE + "/backoffice/public_html/images/document/document.svg"}
          alt=""
        />
      );
    } else if (id === "2") {
      return (
        <img
          src={BASE + "/backoffice/public_html/images/document/image.svg"}
          alt=""
        />
      );
    } else if (id === "3") {
      return (
        <img
          src={BASE + "/backoffice/public_html/images/document/mov.svg"}
          alt=""
        />
      );
    }
  };
  const onCatgegoryCliked = (id) => {
    setCatId(id);
  };
  const onClearFilter = () => {
    setCatId("");
  };
  return (
    <div className="container-fluid">
      <Row className={style.downloadConatiner}>
        <Col xs={6} md={3}>
          <Card>
            <Card.Body>
              <Card.Title>Category</Card.Title>
              <ListGroup as="ul">
                {category.map((catObj, index) => (
                  <ListGroup.Item
                    as="li"
                    key={index}
                    action
                    onClick={() => onCatgegoryCliked(catObj.id)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: index + 1 == catId ? "#7266ba" : "",
                      color: index + 1 == catId ? "white" : "",
                    }}
                  >
                    {catObj.type}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {catId && (
                <div
                  style={{
                    margin: "10px",
                    textAlign: "center",
                  }}
                >
                  <Button onClick={onClearFilter}>Clear</Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={9}>
          <div className={style.userDowmloadSection}>
            {state && state.length > 0 ? (
              state
                .filter((items) => (catId ? items.ctgry == catId : items))
                .map((items, index) => (
                  <>
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_content}`
                          : `${style.content}`
                      }`}
                      key={index}
                    >
                      <div>
                        <img src={items.doc_icone} alt="" width={"100%"} />
                      </div>
                      <div className={style.snddiv}>
                        <div>{items.file_title} </div>
                        <div>{items.uploaded_date}</div>
                        <div>{items.doc_desc}</div>
                      </div>
                      <div className="row">
                        <div className={style.placeright}>
                          <Button
                            variant="success"
                            style={{
                              marginRight: "2px",
                            }}
                          >
                            <a
                              href={items.doc_file_name}
                              target="_blank"
                              className={style.linkcoloor}
                              download
                              rel="noreferrer"
                            >
                              <i className="fa fa-eye" />
                            </a>
                          </Button>
                          <Button variant="success">
                            <a
                              href={items.doc_file_name}
                              target="_blank"
                              className={style.linkcoloor}
                              download
                              onClick={(e) => download(items.doc_file_name)}
                              rel="noreferrer"
                            >
                              <i className="fa fa-download" />
                              {/* download */}
                            </a>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ))
            ) : (
              <>
                <div className={`${style.NodataImage}`}>
                  <center>
                    <img src={nodata} alt="nodata" style={{ width: "50%" }} />
                  </center>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
  // return loading ? (
  //   <Spinner />
  // ) : (
  //   <div className={style.downloadConatiner}>
  //     <div className={style.userDowmloadSection}>
  //       {state && state.length > 0 ? (
  //         state.map((items, index) => (
  //           <div
  //             className={`${
  //               currentTheme == "theme-dark"
  //                 ? `${style.dark_mode_content}`
  //                 : `${style.content}`
  //             }`}
  //             key={index}
  //           >
  //             <div>
  //               <img src={items.doc_icone} alt="" width={"100%"} />
  //             </div>
  //             <div className={style.snddiv}>
  //               <div>{items.file_title} </div>
  //               <div>{items.uploaded_date}</div>
  //               <div>{items.doc_desc}</div>
  //             </div>
  //             <div>
  //               <div className={style.placeright}>
  //                 <Button variant="success">
  //                   {/* <a download="foo.txt" target="_blank" href="http://example.org/assets/avatar.png">download</a> */}

  //                   <a
  //                     href={items.doc_file_name}
  //                     target="_blank"
  //                     className={style.linkcoloor}
  //                     download
  //                     onClick={(e) => download(items.doc_file_name)}
  //                     rel="noreferrer"
  //                   >
  //                     <i className="fa fa-download" />
  //                     download
  //                   </a>
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         ))
  //       ) : (
  //         <>
  //           <div className={`${style.NodataImage}`}>
  //             <center>
  //               <img src={nodata} alt="nodata" style={{ width: "50%" }} />
  //             </center>
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default DownloadContent;
