import React, { useState } from "react";
import style from "./history.module.scss";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import Invoice from "./Invoice";
const HistoryContent = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);
  const [state, setState] = useState({
    modalShow: false,
    modalData: {},
  });

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      modalShow: false,
      modalData: {},
    }));
  };
  const handleShow = (data) => {
    setState((prev) => ({
      ...prev,
      modalShow: true,
      modalData: data,
    }));
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_container}`
          : `${style.container}`
      }`}
    >
      <Table responsive bordered>
        <thead>
          <tr>
            <th>{t("Common.orderId")}</th>
            <th>{t("Common.memberName")}</th>
            <th>{t("Common.totalAmount")}</th>
            <th>{t("payout.paymentMethod")}</th>
            <th>{t("Common.orderDate")}</th>
            <th>{t("Common.action")}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length === 0 ? (
            <td colSpan="13" align="center">
              No Order
            </td>
          ) : (
            props.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.order_id}</td>
                  <td>{item.user_name}</td>
                  <td>
                    {currency.currentCurr}{" "}
                    {(parseFloat(item.total) * currency.value).toFixed(
                      currency.precision
                    )}
                  </td>
                  <td>
                    {item.payment_method ==
                    'Credit/Debit Card (3D and SCA Secured) <img src="catalog/view/theme/default/image/stripe_logo.png" alt="Stripe"/>'
                      ? "Stripe"
                      : item.payment_method}
                  </td>
                  <td>{moment(item.date_added).format("MM-DD-YYYY")}</td>
                  <td className="text-center">
                    <span
                      className={style.actionIcon}
                      onClick={() => handleShow(item)}
                    >
                      <i className="fa fa-eye"></i>
                    </span>
                  </td>
                </tr>
              );
            })
          )}
          <tr></tr>
        </tbody>
      </Table>
      {state.modalData && (
        <Invoice
          show={state.modalShow}
          handleClose={handleClose}
          data={state.modalData}
          currency={currency}
        />
      )}
    </div>
  );
};

export default HistoryContent;
