import React, { useEffect, useState } from "react";
import style from "./tree.module.scss";
import { TitleHeaders } from "./";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import DownLineService from "../../../service/tree/downLine";
import ReactPaginate from "react-paginate";
import Spinner from "../../shared/Spinner";
import { IMG_URL } from "../../../configuration/apiconfig";
import Image from "../../../assets/images/nophoto/no_photo.jpg";
import { useSelector } from "react-redux";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";

//data
import downlineData from "./downlinedata.json";

const DownLineContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);

  const [state, setState] = useState({
    level: "all",
    offset: 0,
    data: [],
    count: 0,
    totalLevels: [],
    showNext: true,
    loading: true,
    searchloader: false,
    page_count: 0,
    userName:'',
    tempData: [],
  });

  useEffect(() => {
  loadData(state.level,state.offset,state.userName);
  }, []);

  const loadData = (level="",offset="",userName="") =>{
    const service = new DownLineService();

    let limit = 10;
    service.getDownline(level, offset,limit,userName).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          data: res.data.tableData,
          tempData: res.data.tableData,
          totalLevels: new Array(res.data.total_levels)
            .fill()
            .map((_, idx) => idx + 1),
          count: res.data.total_downline_count,
          loading: false,
          // level : res.data.total_levels
        }));
      } else {
        history.push("/login");
      }
    });
  }

  const goToPreviousPath = () => {
    history.goBack();
  };

  //onreset button click
  const reset = () => {
    setState((prev) => ({
      ...prev,
      level: "",
      offset: 0,
      userName:''
    }));
  };
  //serch the level wise list
  const searchLevel = (key, level) => {
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        level: level,
        searchloader: false,
      }));
    }, 1000);
  };

  const resetParams  = () =>{
    setState((prev) => ({
      ...prev,
      userName: "",
    }));
     loadData(state.level,state.offset,"");
  }

  const userParamsSearch  = () =>{
    loadData(state.level,state.offset,state.userName);
  }
  const changeDropDown = (search) => {
    setState((prev) => ({
      ...prev,
      userName: search,
    }));
  };
  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 10);
    console.log(selected,offset)
    let showNext = true;
    if (offset > 0) {
      showNext = Math.floor(state.count / offset) === 1 ? false : true;
    }
    setState((prev) => ({
      ...prev,
      offset: offset,
      showNext: showNext,
    }));
    loadData(state.level,offset,state.userName);
  };
  return (
    <div className={style.DownlineMembers}>
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          {history.location.state &&
          history.location.state.prevPath == "/genealogyTree" ? (
            <button className={style.back_btn} onClick={goToPreviousPath}>
              Back
              <i className=" btn_icon fa fa-backward"></i>
            </button>
          ) : (
            <></>
          )}

          <TitleHeaders
            tilesvalue="downlineMembers"
            count={state.count}
            levels={state.totalLevels}
            searchLevel={searchLevel}
            reset={reset}
            searchloader={state.searchloader}
            changeDropDown={changeDropDown}
            userParamsSearch={userParamsSearch}
            resetParams={resetParams}
            {...state}
          />
          <div>
            <Table striped hover responsive className={style.tableData}>
              <thead>
                <tr>
                  <th>{t("Common.memberName")}</th>
                  {state.data[0]?.placement && <th>{t("Common.placement")}</th>}
                  <th>{t("Common.sponsor")}</th>
                  <th>{t("Common.level")}</th>
                  <th>{t("Common.rank")}</th>
                  <th>{t("Common.gpv")}</th>
                  <th>{t("Common.personalPv")}</th>
                  <th>{t("Common.action")}</th>
                   <th>{t("Common.pvHistory")}</th>
                </tr>
              </thead>
              <tbody>
                {state.data.length > 0 ? (
                  state.data.map((items, index) => (
                    <tr key={index}>
                      <td className={style.tableAvatarDetails}>
                        <div>
                          <img
                            src={items.user_photo ? items.user_photo : Image}
                            alt="No Image"
                            className={style.profileImage}
                          />
                          <span
                            className={`${style.active} ${
                              items.active == "1"
                                ? style.active
                                : style.inactive
                            }`}
                          ></span>
                        </div>
                        <div>
                          <div className={style.userName}>
                            {items.first_name} {items.last_name}
                          </div>
                          <span className={style.userid}>{items.username}</span>
                        </div>
                      </td>
                      {items.placement && <td>{items.placement}</td>}
                      <td>{items.sponsor}</td>
                      <td>{items.ref_level}</td>
                      <td>{items.current_rank ? items.current_rank : "NA"}</td>
                      <td>{items.group_pv}</td>

                      <td>{items.personal_pv}</td>

                      <td>
                        <Link
                          to={`/genealogyTree/${items.username}`}
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.themedarkaction}`
                              : `${style.themelightaction}`
                          }`}
                        >
                          <div className={style.tooltip}>
                            <i className="fa fa-sitemap">
                              <span className={style.tooltiptext}>
                                {t("Common.viewgene")}
                              </span>
                            </i>
                          </div>
                        </Link>
                      </td>
                         <td>
                        <Link
                          to={`/downline_points/${items.username}`}
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.themedarkaction}`
                              : `${style.themelightaction}`
                          }`}
                        >
                          <div className={style.tooltip}>
                            <i className="fa fa-history" >
                              <span className={style.tooltiptext}>
                                {t("Common.viewpvHistory")}
                              </span>
                            </i>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className={`${style.NodataImage}`}>
                    <img src={nodata} alt="nodata" style={{ width: "50%" }} />
                  </div>
                )}
              </tbody>
            </Table>
            {
              <ReactPaginate
                previousLabel={t("<<")}
                nextLabel={t(">>")}
                breakLabel={""}
                breakClassName={"break-me"}
                // pageRangeDisplayed={20}
                pageCount={Math.ceil(state.count / 10)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={style.paginationContainer}
                activeClassName={style.active}
                previousLinkClassName={
                  state.offset === 0 ? "d-none" : style.Link
                }
                nextLinkClassName={!state.showNext ? "d-none" : style.Link}
                renderOnZeroPageCount={null}
              />
            }
          </div>
        </>
      )}
    </div>
  );
};

export default DownLineContent;
