import { React, useEffect, useState } from "react";
import style from "./auth.module.scss";
import { IMG_URL } from "../../../configuration/apiconfig";
import AppInfoservice from "../../../service/common/Appinfo";
import Logo from "../../../assets/images/logos/logo-dark.png";
import BrandLogo from "../../../assets/images/logos/brand.png";

const Brand = () => {
  const [logo, setLogo] = useState();
  const service = new AppInfoservice();

  useEffect(() => {
    service.Appinfo().then((res) => {
      if (res.status) {
        setLogo(res.data.company_info.logo);
      } else {
      }
    });
  }, []);

  return (
    <div className={`d-block mt-2 ${style.loginNavBrand}`}>
      <img src={BrandLogo} alt="" className={style.loginNavBrandImg} />
    </div>
  );
};

export default Brand;
